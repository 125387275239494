import React, { useEffect, useState } from 'react';
import './App.css';
import './index.css'
import Modal from 'react-modal';
import { AiFillHome } from 'react-icons/ai'
import { get, orderByChild, push, query, ref } from "firebase/database";
import { dBase } from './firebaseConection'
import InputMask from 'react-input-mask';

interface FormData {
  name: string
  phone: string
  idChurch: string
  model: string
  size: string
  paid: boolean
}

interface Churches {
  id: string
  name: string
  pix: string
  namePix: string
}

Modal.setAppElement('#root');

function App() {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    phone: '',
    idChurch: 'N',
    model: 'N',
    size: 'N',
    paid: false
  });

  const [enviado, setEnviado] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termosAceitos, setTermosAceitos] = useState(false);
  const [churchesList, setChurchesList] = useState<Churches[]>([]);
  const [pix, setPix] = useState('')
  const [namePix, setNamePix] = useState('')

  const loadData = async () => {
    const list: Churches[] = [];

    const dataRef = ref(dBase, 'Churches')

    const queryRef = query(dataRef, orderByChild('name'))

    const snapshot = await get(queryRef);

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {

        const churchData = childSnapshot.val();

        const listChurchesItem: Churches = {
          id: childSnapshot.key,
          name: churchData.name.toString(),
          pix: churchData.pix,
          namePix: churchData.namePix
        };

        list.push(listChurchesItem);
      });

      list.reverse()
    }

    return list;

  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChangeChurch = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    const pixToSend = churchesList.find(item => item.id === value);

    if (pixToSend) {
      setPix(pixToSend!.pix)
      setNamePix(pixToSend!.namePix)
    }
    else {
      setPix('')
      setNamePix('')
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!termosAceitos) {
      alert('Você deve aceitar os termos para continuar.');
      return;
    }

    const regex = /[^0-9\s]/g;
    const telCleaned = formData.phone.replace(regex, '');

    if (formData.name.length < 2) {
      alert('Os nome deve ter pelo menos 2 caracteres.');
      return;
    }

    if (telCleaned.length < 8) {
      alert('Telefone deve ser informado corretamente')
      return
    }

    if (formData.idChurch === "N") {
      alert('Informe sua Igreja');
      return;
    }
    
    if (formData.model === "N") {
      alert('Escolha o Modelo');
      return;
    }

    if (formData.size === "N") {
      alert('Escolha o Tamanho');
      return;
    }

    const dataRef = ref(dBase, 'Women')

    push(dataRef, { ...formData, name: formData.name.toUpperCase(), dtInsert: new Date().toString() })

    setEnviado(true);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadData();
      setChurchesList(data);
    };

    fetchData();
  }, []);

  return (
    <><div className="admin-container">
      <header className="admin-header">
        <nav className='nav-header'>
          <AiFillHome size={28} />
        </nav>
      </header>
      <h1 className='logo'>Ministério<span className='logo-text'>Mulher</span></h1>

      <h1>1ª Caminhada da Prevenção ao Cancer</h1>
      <p>Data do Evento: 29/10/2023</p>
      {!enviado && (<><br />
        <p>
          Para participar do evento, digite as informações:
        </p>
        <form onSubmit={handleSubmit} className="form">

          <label className="label" htmlFor="name">Nome Completo:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required style={{ width: '100%', padding: '10px' }} className="custom-input" />

          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="phone">Telefone WhatsApp:</label>
              <InputMask
                mask="(99) 9 9999-9999"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="(00) 0 0000-0000"
                className="custom-input"
                required
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="idChurch">Igreja:</label>
              <select
                id="idChurch"
                name="idChurch"
                value={formData.idChurch}
                onChange={handleSelectChangeChurch}
                className='custom-input'
                required
              >
                <option value="N" selected disabled>-- Informe sua Igreja --</option>
                {churchesList.map(church => (
                  <option key={church.id} value={church.id}>{church.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="image-container">
            <img
              width={300}
              src="/imgs/camiseta-rosa-do-outubro-rosa-prevencao-do-cancer-de-mama-rosa.jpg"
              alt="Imagem Camiseta"
              className="clickable-image"
            />
          </div>

          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="modelo">Modelo:</label>
              <select
                id="model"
                name="model"
                value={formData.model}
                onChange={handleSelectChange}
                className='custom-input'
                required
              >
                <option value="N" selected disabled>-- Informe o Modelo --</option>
                <option value="Camiseta">Camiseta</option>
                <option value="Baby Look">Baby Look</option>
              </select>
            </div>
          </div>

          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="tamanho">Tamanho:</label>
              <select
                id="size"
                name="size"
                value={formData.size}
                onChange={handleSelectChange}
                className='custom-input'
                required
              >
                <option value="N" selected disabled>-- Informe o tamanho --</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="G">G</option>
                <option value="GG">GG</option>
              </select>
            </div>
          </div>

          <div className="input-row">
            <label className="label" htmlFor="valor">Valor da Camiseta: <b> R$ 35,00</b></label>
          </div>
          {/*  {pix !== '' ? (
            <><div className="input-row2">
              <label className="label" htmlFor="valor">PIX: <b>{pix}</b></label>
            </div><div className="input-row">
                <label className="label" htmlFor="valor">{namePix}</label>
              </div></>
          ) : ''} */}

          <br />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label>
              <input
                type="checkbox"
                checked={termosAceitos}
                onChange={() => setTermosAceitos(!termosAceitos)}
                required
              />{' '}
              Aceito os
            </label>
            &nbsp; <a onClick={openModal}>Termos e Condições</a>
          </div>
          <br />
          <button className='btn-register' type="submit">Gravar</button>
        </form></>)}
      {enviado && (
        <><br /><br />
          <p>
            Obrigado! Seus dados foram gravados com sucesso.
          </p>
          <br />
          <p>Fazer pix para: <b>{pix}</b></p>
          <p>{namePix}</p>
          <br />
          <p>Enviar comprovante para: <b>{pix}</b></p>
        </>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Termos e Condições"
      ><button onClick={closeModal}>Fechar</button>
        <div className="markdown prose w-full break-words dark:prose-invert light">
          <h2>Termos e Condições</h2>
          <p><strong>TERMO DE CONSENTIMENTO PARA COLETA DE DADOS PESSOAIS</strong></p>

          <p>Através deste termo, o usuário, doravante denominado(a) o "Titular dos Dados", concede seu consentimento livre, informado e inequívoco à empresa IASD CENTRAL SÃO JOSÉ DOS CAMPOS, doravante denominada a "Controladora dos Dados", para coletar e processar seus dados pessoais, conforme os termos abaixo:</p>

          <p><strong>1. Dados Pessoais Coletados:</strong>
            A Controladora dos Dados está autorizada a coletar e registrar os seguintes dados pessoais do Titular dos Dados:</p>

          <ul><li>Nome</li><li>Telefone</li></ul>
          <p><strong>2. Finalidade da Coleta:</strong>
            Os dados pessoais acima mencionados serão coletados com a finalidade exclusiva de gerenciar a participação do Titular dos Dados na 1ª Caminhada de Prevenção ao Câncer, que ocorrerá em 29/10/2023.</p>

          <p><strong>3. Compartilhamento de Dados:</strong>
            Os dados pessoais coletados não serão compartilhados com terceiros, exceto quando necessário para o cumprimento da finalidade acima mencionada ou quando exigido por lei.</p>

          <p><strong>4. Armazenamento e Prazo de Retenção:</strong>
            Os dados pessoais do Titular serão mantidos pela Controladora dos Dados pelo tempo necessário para atender à finalidade da coleta. Após o término do evento, os dados serão devidamente excluídos.</p>

          <p><strong>5. Direitos do Titular dos Dados:</strong>
            Como Titular dos Dados, o mesmo tem o direito de solicitar à Controladora dos Dados o acesso, retificação, exclusão ou portabilidade de seus dados pessoais. Também tem o direito de revogar este consentimento a qualquer momento, mediante solicitação à Controladora dos Dados.</p>

          <p><strong>6. Segurança dos Dados:</strong>
            A Controladora dos Dados adotará medidas de segurança adequadas para proteger os dados pessoais do Titular contra acesso não autorizado ou uso indevido.</p>

          <p><strong>7. Lei Aplicável:</strong>
            Este termo está em conformidade com a Lei Geral de Proteção de Dados (LGPD) do Brasil.</p>

          <p><strong>8. Consentimento Voluntário:</strong>
            Titular dos Dados declara que este consentimento é dado de forma voluntária e consciente, após ter sido devidamente informado sobre a coleta e o tratamento de seus dados pessoais.</p>

          São José dos Campos/SP 30/09/2023
        </div>
      </Modal>
    </div></>
  );
}

export default App;
